import { Component, EventEmitter, HostListener, Output, signal } from '@angular/core';
// import { AppHeaderComponent } from 
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})

export class AnalyticsComponent {

  // State Management starts

  //// Tab selection starts
  
  // allTab = true;
  // favouritesTab = false;
  // recentsTab = false;

  // tabs = ['All Products','Recents','Favourites']
  // activeTab = 0

  // selectTab(index: number) : void {
  //   this.activeTab = index;

  //   this.allTab = index === 0;
  //   this.recentsTab = index === 1;
  //   this.favouritesTab = index === 2;
  // }

  //// Tab selection ends

  //// Products starts

  loadedItemsCount = 8

  analyticsProducts = [
    {
      id: 0,
      isDashboard: true,
      isSubscribed: true,
      name: "Market Potential Analysis Board",
      desc: "This dashboard provides an in-depth analysis of market trends and potential growth areas. It is tailored for strategic decision-making based on reliable data insights.",
      createdOn: "12-09-2024",
      lastUpdated: "18-10-2024",
      component: "",
    },
    {
      id: 1,
      isDashboard: false,
      isSubscribed: true,
      name: "Michelin's Retailer Sales Data Insights",
      desc: "A comprehensive dataset offering detailed sales performance insights from Michelin’s eRetail partners. Analyze sales trends and identify growth opportunities.",
      createdOn: "05-06-2024",
      lastUpdated: "15-07-2024",
      component: "",
    },
    {
      id: 2,
      isDashboard: true,
      isSubscribed: false,
      name: "Product Level Market Trends",
      desc: "Explore individual product market trends with detailed visualizations and predictive analytics. Perfect for tracking product performance over time.",
      createdOn: "22-08-2023",
      lastUpdated: "01-09-2023",
      component: "",
    },
    {
      id: 3,
      isDashboard: true,
      isSubscribed: true,
      name: "Product Growth Potential Dashboard",
      desc: "This dashboard highlights potential growth opportunities for products across various markets. It includes detailed competitor and market share analysis.",
      createdOn: "19-11-2023",
      lastUpdated: "30-11-2023",
      component: "",
    },
    {
      id: 4,
      isDashboard: false,
      isSubscribed: true,
      name: "Retail Performance Metrics Report",
      desc: "Get a detailed breakdown of retail performance metrics, including sales volume, revenue growth, and customer segmentation for better decision-making.",
      createdOn: "02-01-2024",
      lastUpdated: "15-01-2024",
      component: "",
    },
    {
      id: 5,
      isDashboard: true,
      isSubscribed: false,
      name: "Future Product Insights Platform",
      desc: "Gain actionable insights on emerging product trends and consumer behavior using this advanced analytics platform designed for innovation teams.",
      createdOn: "10-03-2024",
      lastUpdated: "20-03-2024",
      component: "",
    },
    {
      id: 6,
      isDashboard: false,
      isSubscribed: false,
      name: "Michelin's Global Sales Tracker",
      desc: "Track Michelin’s global sales performance in real-time. The tool includes regional breakdowns, predictive models, and exportable reports.",
      createdOn: "05-12-2023",
      lastUpdated: "20-12-2023",
      component: "",
    },
    {
      id: 7,
      isDashboard: true,
      isSubscribed: true,
      name: "Regional Market Potential Analysis",
      desc: "Focuses on potential market growth in specific regions, highlighting unique factors influencing product sales and consumer demand.",
      createdOn: "15-07-2023",
      lastUpdated: "10-08-2023",
      component: "",
    },
    {
      id: 8,
      isDashboard: false,
      isSubscribed: false,
      name: "Retailer Sales Growth Predictor",
      desc: "Predict sales growth with advanced AI models that analyze historical data and market trends. Tailored for Michelin’s retail network.",
      createdOn: "08-02-2024",
      lastUpdated: "25-02-2024",
      component: "",
    },
    {
      id: 9,
      isDashboard: true,
      isSubscribed: true,
      name: "Global Product-Level Analysis Dashboard",
      desc: "Detailed analysis of product-level performance across global markets. Includes forecasting tools and region-specific insights.",
      createdOn: "23-10-2023",
      lastUpdated: "30-10-2023",
      component: "",
    },
    {
      id: 10,
      isDashboard: false,
      isSubscribed: true,
      name: "Retail Trends Visualization Tool",
      desc: "Visualize retail trends with interactive graphs and charts. Perfect for understanding shifts in consumer behavior over time.",
      createdOn: "04-06-2024",
      lastUpdated: "20-06-2024",
      component: "",
    },
    {
      id: 11,
      isDashboard: true,
      isSubscribed: false,
      name: "Innovative Product Strategies Hub",
      desc: "Designed for teams exploring innovative strategies for products, this tool provides detailed competitor analysis and strategic recommendations.",
      createdOn: "11-09-2023",
      lastUpdated: "25-09-2023",
      component: "",
    },
    {
      id: 12,
      isDashboard: true,
      isSubscribed: true,
      name: "Competitor Market Share Dashboard",
      desc: "A comprehensive tool for monitoring competitor market shares, including trend analysis and actionable insights for competitive positioning.",
      createdOn: "01-02-2024",
      lastUpdated: "18-02-2024",
      component: "",
    },
    {
      id: 13,
      isDashboard: false,
      isSubscribed: true,
      name: "Sales Conversion Rate Tracker",
      desc: "Track and analyze sales conversion rates across different channels. Includes automated reporting and custom alerts for significant changes.",
      createdOn: "14-11-2023",
      lastUpdated: "28-11-2023",
      component: "",
    },
    {
      id: 14,
      isDashboard: true,
      isSubscribed: false,
      name: "Consumer Sentiment Insights Platform",
      desc: "Analyze consumer sentiment data to understand preferences and satisfaction levels. Integrates with social media and survey platforms.",
      createdOn: "20-07-2023",
      lastUpdated: "05-08-2023",
      component: "",
    },
    {
      id: 15,
      isDashboard: true,
      isSubscribed: true,
      name: "Revenue Growth Predictor Dashboard",
      desc: "Leverages machine learning algorithms to predict future revenue growth based on historical and real-time data trends.",
      createdOn: "22-12-2023",
      lastUpdated: "08-01-2024",
      component: "",
    },
    {
      id: 16,
      isDashboard: false,
      isSubscribed: true,
      name: "Michelin Sales Opportunity Insights",
      desc: "Identify sales opportunities and new revenue streams for Michelin products through advanced data analysis and reporting tools.",
      createdOn: "30-03-2024",
      lastUpdated: "15-04-2024",
      component: "",
    },
    {
      id: 17,
      isDashboard: true,
      isSubscribed: false,
      name: "Product Performance Monitoring Dashboard",
      desc: "Monitor the performance of individual products across different markets and channels. Offers visualized data and actionable insights for optimization.",
      createdOn: "17-10-2023",
      lastUpdated: "30-10-2023",
      component: "",
    },
    {
      id: 18,
      isDashboard: false,
      isSubscribed: true,
      name: "Retail Channel Optimization Tool",
      desc: "Optimize retail channel performance using data-driven recommendations. Includes analytics on inventory, pricing, and customer engagement.",
      createdOn: "09-05-2024",
      lastUpdated: "25-05-2024",
      component: "",
    },
    {
      id: 19,
      isDashboard: true,
      isSubscribed: true,
      name: "Advanced Product-Level Market Segmentation Dashboard",
      desc: "Segment your market based on detailed demographic, geographic, and behavioral data to create targeted marketing strategies.",
      createdOn: "05-09-2023",
      lastUpdated: "25-09-2023",
      component: "",
    },
    {
      id: 20,
      isDashboard: false,
      isSubscribed: false,
      name: "Michelin's Quarterly Sales Overview",
      desc: "Access quarterly sales data for Michelin products with insights on performance by region, product category, and customer type.",
      createdOn: "14-07-2023",
      lastUpdated: "01-08-2023",
      component: "",
    },
    {
      id: 21,
      isDashboard: true,
      isSubscribed: false,
      name: "Predictive Consumer Behavior Analysis",
      desc: "Predict consumer behavior patterns and preferences using AI models trained on historical and market data for better decision-making.",
      createdOn: "22-02-2024",
      lastUpdated: "12-03-2024",
      component: "",
    },
    {
      id: 22,
      isDashboard: false,
      isSubscribed: true,
      name: "Retail Inventory Management Tool",
      desc: "Helps retailers manage inventory levels with predictive analytics to minimize stockouts and optimize stock turnover.",
      createdOn: "09-08-2023",
      lastUpdated: "18-08-2023",
      component: "",
    },
    {
      id: 23,
      isDashboard: true,
      isSubscribed: true,
      name: "Consumer Demographic Insights Dashboard",
      desc: "A tool for analyzing consumer demographics, helping businesses target their audience effectively with customized offerings.",
      createdOn: "04-11-2023",
      lastUpdated: "19-11-2023",
      component: "",
    },
    {
      id: 24,
      isDashboard: false,
      isSubscribed: false,
      name: "Michelin's eCommerce Revenue Report",
      desc: "A detailed report highlighting eCommerce revenue trends and performance metrics for Michelin’s online sales.",
      createdOn: "16-05-2024",
      lastUpdated: "30-05-2024",
      component: "",
    },
    {
      id: 25,
      isDashboard: true,
      isSubscribed: false,
      name: "Regional Product Performance Dashboard",
      desc: "Provides insights into how products are performing in specific regions, with actionable data to improve regional strategies.",
      createdOn: "02-04-2024",
      lastUpdated: "18-04-2024",
      component: "",
    },
    {
      id: 26,
      isDashboard: false,
      isSubscribed: true,
      name: "Sales Funnel Optimization Tool",
      desc: "Optimize your sales funnel using advanced analytics to improve conversion rates, reduce drop-offs, and increase customer satisfaction.",
      createdOn: "11-10-2023",
      lastUpdated: "30-10-2023",
      component: "",
    },
    {
      id: 27,
      isDashboard: true,
      isSubscribed: true,
      name: "Global Market Analysis Dashboard",
      desc: "Analyze market trends across the globe with detailed reports, interactive charts, and actionable recommendations for your business.",
      createdOn: "28-11-2023",
      lastUpdated: "08-12-2023",
      component: "",
    },
    {
      id: 28,
      isDashboard: false,
      isSubscribed: false,
      name: "Retailer Price Sensitivity Analysis",
      desc: "Understand how price changes impact sales for retail products with advanced elasticity models and historical trend analysis.",
      createdOn: "12-03-2024",
      lastUpdated: "25-03-2024",
      component: "",
    },
    {
      id: 29,
      isDashboard: true,
      isSubscribed: true,
      name: "Product Profitability Tracking Dashboard",
      desc: "Track the profitability of individual products with visualized data, including cost breakdowns and revenue comparisons across markets.",
      createdOn: "20-08-2023",
      lastUpdated: "01-09-2023",
      component: "",
    },
    {
      id: 30,
      isDashboard: true,
      isSubscribed: false,
      name: "Sustainability Impact Dashboard",
      desc: "Analyze the environmental and social impact of products and business operations. Includes carbon footprint tracking and sustainability KPIs.",
      createdOn: "15-02-2024",
      lastUpdated: "18-02-2024",
      component: "",
    },
    {
      id: 31,
      isDashboard: false,
      isSubscribed: true,
      name: "Retailer Discount Optimization Tool",
      desc: "Optimize discount strategies to maximize sales and maintain profitability using data-driven insights.",
      createdOn: "01-03-2024",
      lastUpdated: "05-03-2024",
      component: "",
    },
    {
      id: 32,
      isDashboard: true,
      isSubscribed: true,
      name: "Seasonal Sales Trends Dashboard",
      desc: "Track seasonal sales patterns and uncover key insights to optimize marketing and inventory strategies.",
      createdOn: "12-01-2024",
      lastUpdated: "15-01-2024",
      component: "",
    },
    {
      id: 33,
      isDashboard: true,
      isSubscribed: false,
      name: "Global Competitor Insights Dashboard",
      desc: "Compare global competitor performance metrics and market share across different regions.",
      createdOn: "25-11-2023",
      lastUpdated: "02-12-2023",
      component: "",
    },
    {
      id: 34,
      isDashboard: false,
      isSubscribed: true,
      name: "Michelin's Monthly Revenue Summary",
      desc: "Quick access to monthly revenue summaries for Michelin products across all key markets.",
      createdOn: "10-06-2024",
      lastUpdated: "15-06-2024",
      component: "",
    },
    {
      id: 35,
      isDashboard: true,
      isSubscribed: false,
      name: "Market Entry Feasibility Dashboard",
      desc: "Analyze the feasibility of entering new markets with detailed risk assessments and opportunity forecasts.",
      createdOn: "27-04-2024",
      lastUpdated: "30-04-2024",
      component: "",
    },
    {
      id: 36,
      isDashboard: false,
      isSubscribed: true,
      name: "Retailer Demand Forecasting Tool",
      desc: "Forecast retailer demand with AI models for optimal production and distribution planning.",
      createdOn: "03-08-2024",
      lastUpdated: "05-08-2024",
      component: "",
    },
    {
      id: 37,
      isDashboard: true,
      isSubscribed: true,
      name: "Regional Sales and Marketing Dashboard",
      desc: "Integrates sales and marketing metrics to provide a holistic view of regional performance.",
      createdOn: "01-09-2024",
      lastUpdated: "05-09-2024",
      component: "",
    },
    {
      id: 38,
      isDashboard: true,
      isSubscribed: false,
      name: "Retailer Profitability Analytics",
      desc: "Provides detailed profitability metrics for individual retailers, enabling data-driven partnership decisions.",
      createdOn: "18-07-2024",
      lastUpdated: "20-07-2024",
      component: "",
    },
    {
      id: 39,
      isDashboard: false,
      isSubscribed: true,
      name: "Michelin's Market Expansion Insights",
      desc: "Discover insights into new markets where Michelin products could thrive.",
      createdOn: "15-03-2024",
      lastUpdated: "20-03-2024",
      component: "",
    },
    {
      id: 40,
      isDashboard: true,
      isSubscribed: true,
      name: "Retailer Revenue Growth Dashboard",
      desc: "Track and visualize retailer revenue growth trends across different product categories.",
      createdOn: "05-06-2024",
      lastUpdated: "10-06-2024",
      component: "",
    },
    {
      id: 41,
      isDashboard: false,
      isSubscribed: false,
      name: "Price Benchmarking Tool",
      desc: "Compare product pricing across competitors and regions to optimize pricing strategies.",
      createdOn: "10-11-2024",
      lastUpdated: "12-11-2024",
      component: "",
    },
    {
      id: 42,
      isDashboard: true,
      isSubscribed: false,
      name: "Customer Retention Analysis Dashboard",
      desc: "Identify key factors influencing customer retention and churn for strategic improvement.",
      createdOn: "30-01-2024",
      lastUpdated: "05-02-2024",
      component: "",
    },
    {
      id: 43,
      isDashboard: true,
      isSubscribed: true,
      name: "Digital Marketing ROI Dashboard",
      desc: "Analyze the ROI of digital marketing campaigns with detailed insights into ad performance and conversion metrics.",
      createdOn: "22-09-2024",
      lastUpdated: "25-09-2024",
      component: "",
    },
    {
      id: 44,
      isDashboard: false,
      isSubscribed: true,
      name: "Retailer Customer Behavior Tracker",
      desc: "Track and analyze customer behavior trends in retail stores using real-time data.",
      createdOn: "12-10-2024",
      lastUpdated: "14-10-2024",
      component: "",
    },
    {
      id: 45,
      isDashboard: true,
      isSubscribed: false,
      name: "Market Disruption Insights Platform",
      desc: "Gain insights into potential market disruptions and prepare strategies for mitigating risks.",
      createdOn: "04-04-2024",
      lastUpdated: "06-04-2024",
      component: "",
    },
    {
      id: 46,
      isDashboard: false,
      isSubscribed: true,
      name: "Sales and Inventory Health Report",
      desc: "Provides detailed insights into sales and inventory health for effective supply chain management.",
      createdOn: "19-08-2024",
      lastUpdated: "22-08-2024",
      component: "",
    },
    {
      id: 47,
      isDashboard: true,
      isSubscribed: true,
      name: "Emerging Consumer Trends Dashboard",
      desc: "Uncover emerging consumer trends to stay ahead in dynamic markets with visualized insights.",
      createdOn: "30-05-2024",
      lastUpdated: "02-06-2024",
      component: "",
    },
    {
      id: 48,
      isDashboard: false,
      isSubscribed: false,
      name: "Retail Network Efficiency Analysis",
      desc: "Analyze and improve the efficiency of retail networks using performance metrics and optimization recommendations.",
      createdOn: "01-12-2023",
      lastUpdated: "04-12-2023",
      component: "",
    },
    {
      id: 49,
      isDashboard: true,
      isSubscribed: true,
      name: "Real-Time Sales Tracking Dashboard",
      desc: "Track real-time sales data across all channels and regions to make informed decisions.",
      createdOn: "14-02-2024",
      lastUpdated: "16-02-2024",
      component: "",
    },
    {
      id: 50,
      isDashboard: true,
      isSubscribed: false,
      name: "Competitive Pricing Analysis Tool",
      desc: "Stay competitive by monitoring and analyzing pricing strategies used by competitors.",
      createdOn: "23-07-2024",
      lastUpdated: "28-07-2024",
      component: "",
    },
    {
      id: 51,
      isDashboard: false,
      isSubscribed: true,
      name: "Regional Consumer Spending Report",
      desc: "Detailed reports on consumer spending patterns across regions to align marketing strategies.",
      createdOn: "06-03-2024",
      lastUpdated: "10-03-2024",
      component: "",
    },
    {
      id: 52,
      isDashboard: true,
      isSubscribed: false,
      name: "Demand-Supply Gap Analysis Dashboard",
      desc: "Identify and address demand-supply gaps with actionable recommendations and visualizations.",
      createdOn: "11-11-2024",
      lastUpdated: "13-11-2024",
      component: "",
    },
    {
      id: 53,
      isDashboard: true,
      isSubscribed: true,
      name: "Customer Feedback Analysis Platform",
      desc: "Analyze customer feedback from multiple sources to improve product offerings and services.",
      createdOn: "05-05-2024",
      lastUpdated: "08-05-2024",
      component: "",
    },
    {
      id: 54,
      isDashboard: false,
      isSubscribed: false,
      name: "Michelin’s Supply Chain Analytics Tool",
      desc: "Optimize supply chain operations with detailed analytics and predictive modeling.",
      createdOn: "17-10-2024",
      lastUpdated: "19-10-2024",
      component: "",
    },
    {
      id: 55,
      isDashboard: true,
      isSubscribed: true,
      name: "Global Retail Sales Heatmap",
      desc: "Visualize retail sales performance using an interactive heatmap for global and regional insights.",
      createdOn: "22-08-2024",
      lastUpdated: "24-08-2024",
      component: "",
    },
    {
      id: 56,
      isDashboard: true,
      isSubscribed: false,
      name: "Retailer Performance Comparison Tool",
      desc: "Compare performance metrics of different retailers for strategic alignment and optimization.",
      createdOn: "08-04-2024",
      lastUpdated: "10-04-2024",
      component: "",
    },
    {
      id: 57,
      isDashboard: false,
      isSubscribed: true,
      name: "Sales Seasonality Analytics Report",
      desc: "Detailed seasonal sales reports to optimize promotional and inventory strategies.",
      createdOn: "01-09-2024",
      lastUpdated: "04-09-2024",
      component: "",
    },
    {
      id: 58,
      isDashboard: true,
      isSubscribed: false,
      name: "Predictive Marketing Analytics Dashboard",
      desc: "Optimize marketing campaigns with predictive analytics and insights for better ROI.",
      createdOn: "29-05-2024",
      lastUpdated: "02-06-2024",
      component: "",
    },
    {
      id: 59,
      isDashboard: false,
      isSubscribed: true,
      name: "Retailer Sales Channel Insights",
      desc: "Analyze the performance of different sales channels within the retail network for improved planning.",
      createdOn: "19-11-2023",
      lastUpdated: "22-11-2023",
      component: "",
    },
  ];
  
  //// Product ends

  //// Filters for cards starts

  isDropdownOpen = false
  selectedItems: any[] = []; 
  @Output() selectionChange = new EventEmitter<string[]>();

  typeOptions = [
    {
      id: 0,
      name: 'Dashboards',
      isDashboard: true,
    },
    {
      id: 1,
      name: 'Datasets',
      isDashboard: false,
    }
  ]

  @HostListener('window:scroll', [])
  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadMoreItems();
    }
  }

  loadMoreItems() {
    const totalItems = this.analyticsProducts.length;
    if (this.loadedItemsCount < totalItems) {
      this.loadedItemsCount += 8;
    }
  }

  showOptions() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  isSelected(optionId: any): boolean {
    return this.selectedItems.includes(optionId);
  }

  toggleSelection(optionId: any) {
    if (!this.isSelected(optionId)) {
      this.selectedItems = [optionId];
    } else {
      this.selectedItems = [];
    }
    this.selectionChange.emit(this.selectedItems);
    this.isDropdownOpen = false;
    this.loadedItemsCount = 8;
  }

  getSelectedNames(): string[] {
    return this.selectedItems.map((id: number) => {
      const selectedOption = this.typeOptions.find(option => option.id === id);
      return selectedOption ? selectedOption.name : '';
    });
  }

  clearSelection() {
    this.selectedItems = [];
    this.selectionChange.emit(this.selectedItems);
    this.isDropdownOpen = !this.isDropdownOpen;
    this.loadedItemsCount = 8; 
  }

  ////// Search feature for table starts

  searchTerm = signal('')
  
  get currentSet() {
    return this.analyticsProducts;
  }

  get itemCount() {
    return this.currentSet.length;
  }

  get filteredItems() {
    const selectedOption = this.typeOptions.find(option =>
      this.selectedItems.includes(option.id)
    );
  
    const filtered = this.analyticsProducts.filter(product => {
      const matchesSearchTerm = product.name
        .toLowerCase()
        .includes(this.searchTerm().toLowerCase()); 
  
      const matchesDropdown =
        !selectedOption || 
        selectedOption.id === 0 || 
        selectedOption.isDashboard === product.isDashboard;
  
      return matchesSearchTerm && matchesDropdown;
    });
  
    return filtered.slice(0, this.loadedItemsCount); 
  }

  ////// Search feature for table ends

  //// Filters for cards ends
  
  //// Sorting for cards starts

  isSortingOpen = false

  showSorting() {
    this.isSortingOpen = !this.isSortingOpen
  }
  
  sortByNameAZ() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a, b) => a.name.localeCompare(b.name));
  }
  
  sortByNameZA() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((b, a) => b.name.localeCompare(a.name));
  }

  sortByRecentlyUpdated() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a, b) => {
      const dateA = new Date(a.lastUpdated.split("-").reverse().join("-")).getTime();
      const dateB = new Date(b.lastUpdated.split("-").reverse().join("-")).getTime();
      return dateB - dateA;
    });
  }

  sortByRecentlyAdded() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort((a, b) => {
      const dateA = new Date(a.createdOn.split("-").reverse().join("-")).getTime();
      const dateB = new Date(b.createdOn.split("-").reverse().join("-")).getTime();
      this.isSortingOpen = !this.isSortingOpen;
      return dateB - dateA;
    });
  }

  sortByFav() {
    this.isSortingOpen = !this.isSortingOpen;
    return this.currentSet.sort()
  }

  //// Sorting for cards starts

  // State Management ends
}
