import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateSubscriptionService } from './create-subscription.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ManageSubscriptionService } from '../manage-subscription.service';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { checkUserEmail } from 'src/app/core/Validators/checkUserEmail.validator';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subs.component.html',
  styleUrls: ['./edit-subs.component.scss'],
})
export class EditSubscriptionComponent implements OnInit, OnDestroy {

  @ViewChild("customerName") customer: ElementRef;

  FistNameKeyword = "firstName";
  LastNameKeyword = "lastName";
  CustomerNameKeyword = "companyName"
  subscriptionForm!: FormGroup;
  formlist: any = [];
  moduleFilterDropdownSettings: IDropdownSettings = {};
  subModuleFilterDropdownSettings: IDropdownSettings = {};
  selectedFilterModule: any = [];
  selectedFilterSubModule: any = [];
  modules: any = [];
  id: string = '';
  isAdmin: boolean = false;
  isDisabled: boolean = true;
  createdFromStart: any;
  createdToEnd: any;
  mode: any = [];
  datamode: any = [];
  subModule: any = [];
  successMessage: string = null;
  neverEndSubscription: boolean = false;
  @ViewChild('auto') auto: any;
  keyword = 'companyName';
  customerRes: any;
  public customerData: any;
  customers: any = [];
  companyName: string = '';
  selectedFilterType: any = [];
  filteredSearchValue: any;
  isAdminSelection: boolean = false;
  errorMessage: string = null;
  clearSubscription: Subscription;
  subscriptionId: number;
  subscriptionRes: any;
  public subscriptionResData: any;
  subscriptionDetails: any = [];
  getSubscriptionDetails: any = '';
  getUserDetails: any;
  isCancelActive: boolean = false;
  isPopupVisible: boolean = false;
  resModules: any = [];
  customerList: any[];
  firstNameList: any[];
  lastNameList: any[];
  userType: any;
  editMode = false;
  neverEndSubFlag = false;
  subUsers: any = []
  userDetails: any[];
  allCustomerList: any[];
  filterUserDetails: any[];
  filterCustomers: Observable<any>;
  warningMessage: any;
  filterSubscription: any = []
  allSubscription: any = []
  filtertedSubscriptionWhichAreRemoved: any = [];
  updatedSelectedFilterSubModule: any = []
  oldSelectedFilterSubModule: any = []
  activeSelectedFilterSubModule: any = []
  finalSelectedFilterSubModule: any = []
  isSubscriptionModified: boolean = false;
  showOtherSelectedGroupsFlag = false;
  noAdminPresent = false
  isDuplicateEmail = false;
  deletedUsers: any = [];
  existedUserIds: any = [];
  isExistedUserDeleted: boolean = false;
  isNewUserDeleted: boolean = false;
  isModuleChanged: boolean = false;
  minDate: Date;
  maxDate: Date;
  modulePopupFlag = false
  activeTab = 0
  chips: any = [];
  startIndex = 0;
  itemsPerSlide = 3;
  allModules: any = []
  activeTabUserGroups: any = []
  activeTabModules: any = []
  searchText: any
  activeTabUserGroupsCopy: any = []
  checkedUserGroups: string[] = [];
  checkedGroupsArray: any = []
  totalGroupAssignedCount = 0
  groupLimit = 4
  tempModules: any = []
  currentUserId = ''
  usersArrayCopy: any = []
  apiModules: any = [];
  apiUserGroups: any = [];
  selectedChip: any;
  constructor(
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private createSubscriptionService: CreateSubscriptionService,
    private utilService: UtilService,
    private homePageService: HomePageService,
    private router: Router,
    private subscriptionService: ManageSubscriptionService,
    private fb: FormBuilder,


  ) { this.setMinMaxDates(); }

  setMinMaxDates() {
    const now = moment();
    this.minDate = now.startOf('month').toDate();
    this.maxDate = now.endOf('month').toDate();
  }
  ngOnInit(): void {
    this.subscriptionService.usersArray.next([])
    this.isSubscriptionModified = false;
    this.noAdminPresent = false;
    this.deletedUsers = [];
    this.isModuleChanged = false;
    this.subscriptionId = +this.route.snapshot.params['subscriptionId'];
    this.userType = localStorage.getItem('userType');
    this.utilService.getUserId.subscribe((id: any) => {
      this.id = id as string;
    });
    if (localStorage.getItem('userId')) {
      this.id = localStorage.getItem('userId');
    }
    this.initForm();
    this.getModules();
    this.onAddNew();
    this.fetchCustomers();
    this.loadApiModules();


    //----search customers mto match with input text-----
    this.filterCustomers = this.subscriptionForm.get('customerName').valueChanges.pipe(
      startWith(''),
      map(value => {
        return value ? this._filter(value as string) : this.allCustomerList?.slice();
      })
    );


    // Modules Search settings
    this.moduleFilterDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'moduleName',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    // SubModules Search settings
    this.subModuleFilterDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'moduleName',
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
  }

  //---Get UserData for a CustomerId
  ngAfterViewInit() {
    this.subscriptionForm.get('users').valueChanges.subscribe(res => {
      this.isSubscriptionValid();
      this.isUserScubscriptionValid();
      this.checkDuplicateEmail()
    })
    this.subscriptionService.activeUserGroup.subscribe((res) => {
      this.activeTabUserGroupsCopy = res
    })
    this.subscriptionService.usersArray.subscribe((res) => {
      this.usersArrayCopy = res
    })

  }


  //------to filter input customer from original list of customers----
  private _filter(name: string): any {
    const filterValue = name.toLowerCase();
    return this.allCustomerList?.filter((option: any) => (option as string)?.toLowerCase().includes(filterValue));
  }

  displayFn(customer: any): string {
    return customer ? customer : '';
  }
  displayFNFn(option: any) {
    return option && option?.firstName ? option.firstName : (typeof option != 'object' ? option : '');
  }
  displayLNFn(option: any) {
    return option && option?.lastName ? option.lastName : (typeof option != 'object' ? option : '');
  }
  selectedUser(user: any, userIndex: any) {
    if(user?.statusName=="ACTIVE"){
      this.isSubscriptionModified = true;
      this.users?.controls[userIndex]?.get('id').setValue(user['platformUserId']);
      this.users?.controls[userIndex]?.get('firstName').setValue(user['firstName']);
      this.users?.controls[userIndex]?.get('lastName').setValue(user['lastName']);
      this.users?.controls[userIndex]?.get('email').setValue(user['email'])
    }else{
      this.users?.controls[userIndex]?.get('id').setValue('');
      this.users?.controls[userIndex]?.get('firstName').setValue('');
      this.users?.controls[userIndex]?.get('lastName').setValue('');
      this.users?.controls[userIndex]?.get('email').setValue('')
      this.errorMessage = "User you are trying to attach is Inactive";
      setTimeout(() => {
        this.errorMessage = null;
      }, 5000);
    }
  }

  onSelectCustomer(searchCustomer: any) {
    this.selectedFilterSubModule = [];
    this.userDetails = [];
    this.filterUserDetails = [];

    const customer = this.customers?.filter((cust: any) =>
      (cust.companyName as string).toLowerCase() == searchCustomer.toLowerCase()
    )[0];

    this.users.controls.forEach(control => {
      control.get('isAdmin').setValue('');
      control.get('id').setValue('');
      control.get('firstName').setValue('');
      control.get('lastName').setValue('');
      control.get('email').setValue('');
    });

    //-----Remove User Details form except first form---
    let rowCount = 0;
    let initialLength = this.users.controls.length;
    while (rowCount < initialLength) {
      if (this.users.controls.length > 1)
        this.users.controls.pop();
      rowCount++;
    }

    this.createSubscriptionService.loadUsersByCustomerId(customer?.companyId).subscribe({
      next: (res: any) => {
        if (res.code !== 200) {
          console.log('error');
          this.warningMessage = res.message
        } else {
          this.userDetails = res.result;
        }
      },
    });
  }


  onChangeUserSearch(searchName: string, action: string) {
    this.filterUserDetails = this.userDetails?.filter(user => user[action]?.toLowerCase().includes(searchName.toLowerCase()));
  }


  onCheckBoxChange(index: number) {
    const controls = this.users.controls;
    const isSelected = controls[index].get('isAdmin')?.value;
    controls.forEach((control, i) => {
      if (isSelected) control.get('isAdmin')?.patchValue(i === index);
    });
    this.checkIsAdmin()
    this.isModuleChanged = true;
  }

  fetchCustomers() {
    this.subscriptionService.getCustomersByUser(this.id).subscribe({
      next: (res: any) => {
        if (res.code !== 200) {
          console.log('error');
        } else {
          this.customerRes = res;
          this.customerData = this.customerRes.result;
          this.customers = [...this.customerData];
          this.allCustomerList = this.customers.map((m: any) => m.companyName);
          if (this.subscriptionId) {
            this.editMode = true;
            this.loadSubscriptionDetailsOnEdit();
          }
        }
      },
    });
  }

  loadSubscriptionDetailsOnEdit() {
    this.createSubscriptionService
      .loadSubscriptionDetails(this.subscriptionId, this.id)
      .subscribe({
        next: (res: any) => {
          this.subscriptionRes = _.cloneDeep(res);
          this.subscriptionResData = _.cloneDeep(this.subscriptionRes.result);
          this.getSubscriptionDetails =
            _.cloneDeep(this.subscriptionResData);

          this.getUserDetails = _.cloneDeep(this.subscriptionResData.users);
          this.subscriptionForm.patchValue({
            subscriptionId: this.getSubscriptionDetails.subscriptionId,
            subscriptionName: this.getSubscriptionDetails.subscriptionName,
            startDate: new Date(this.getSubscriptionDetails.startDate),
            endDate: new Date(this.getSubscriptionDetails.endDate),
            customerName: this.getSubscriptionDetails.customerName
          });
          this.allSubscription = _.cloneDeep(this.getSubscriptionDetails.subscriptionModules)
          this.filterSubscription = this.getSubscriptionDetails.subscriptionModules.filter((x: any) => x.isModuleAttached == true)
          this.onSelectCustomer(this.getSubscriptionDetails.customerName)
          this.selectedFilterModule = [...this.filterSubscription];
          this.subscriptionForm.get('neverEndSubscription').patchValue(this.getSubscriptionDetails.neverEndSubscription)
          this.subModule = [...this.selectedFilterModule];
          this.fillUserDetails(this.getUserDetails.length);
          if (this.subscriptionResData.neverEndSubscription == true) {
            this.neverEndSubFlag = true;
          } else {
            this.neverEndSubFlag = false;
          }
          this.users.value.forEach((item: any) => {
            let index = this.subscriptionRes.result.users.findIndex((ele: any) => ele.id == item.id);
            if (index > -1) {
              item.userModules = _.cloneDeep(this.subscriptionRes.result.users[index].userModules.filter((ele: any) => ele.isModuleAttached == true));
            }
          })
          this.subscriptionService.usersArray.next(this.users.value)
        },
      });
  }

  isSubscriptionValidForUser() {
    if (this.isExistedUserDeleted)
      return true;
    else if ((!this.isExistedUserDeleted && this.isNewUserDeleted))
      return true; // 
    else
      return true;
  }


  fillUserDetails(lengthform: number) {
    while (this.users.length < lengthform) {
      const userDetails = this.getUserDetails[this.users.length] || {};
      const formGroup = new FormGroup({
        isAdmin: new FormControl(userDetails.isAdmin || false),
        id: new FormControl(userDetails.platformUserId || 0),
        firstName: new FormControl(userDetails.firstName || ''),
        lastName: new FormControl(userDetails.lastName || ''),
        email: new FormControl(userDetails.email || ''),
        statusId: new FormControl(userDetails.statusId || ''),
        statusName: new FormControl(userDetails.statusName || ''),
        userModules: new FormArray([]), // Initialize with an empty FormArray
      });
      this.users.push(formGroup);
    }


    this.users.controls
      .slice(0, lengthform)
      .forEach((formGroup: any, index: number) => {
        const userDetails = this.getUserDetails[index] || {};
        const firstName = formGroup.get('firstName')?.value;
        if (!firstName) {
          this.users.removeAt(0);
          // myArray.insert(0, this.fb.control(value));
          this.users.insert(0, new FormGroup({
            isAdmin: new FormControl(userDetails.isAdmin || true),
            id: new FormControl(userDetails.platformUserId || 0),
            firstName: new FormControl(userDetails.firstName || ''),
            lastName: new FormControl(userDetails.lastName || ''),
            email: new FormControl(userDetails.email || ''),
            statusId: new FormControl(userDetails.statusId || ''),
            statusName: new FormControl(userDetails.statusName || ''),
            userModules: new FormArray([]), // Initialize with an empty FormArray
          }));
          formGroup.patchValue({
            isAdmin: userDetails.isAdmin,
            firstName: userDetails.firstName || '',
            lastName: userDetails.lastName || '',
            email: userDetails.email || '',
            statusId: userDetails.statusId || '',
            statusName: userDetails.statusName || '',
          });
        }
        let filterSubscription = userDetails.userModules.filter((x: any) => x.isModuleAttached == true)

        this.selectedFilterSubModule[index] = [...filterSubscription];
        (<FormArray>this.subscriptionForm.get('users'))
          .controls
          .forEach((control: any, i: any) => {
            this.users.controls[i].patchValue({
              id: this.getUserDetails[i].id,
            });
            this.existedUserIds.push(this.getUserDetails[i].id);
          })
      });
    (<FormArray>this.subscriptionForm.get('users'))
      .controls
      .forEach((control: any, i: any) => {
        this.users.controls[i].patchValue({
          id: this.getUserDetails[i].id,
        });

        const addressFormGroup = this.users.at(i) as FormGroup;
        (addressFormGroup.get('userModules') as FormArray).clear();

        this.getUserDetails[i].userModules.forEach((item: any, idx: any) => {
          if (item.id > 0) {

            if (item.isModuleAttached != '') {
              (addressFormGroup.get('userModules') as FormArray).push(
                new FormGroup({
                  id: new FormControl(item.id),
                  moduleName: new FormControl(item.moduleName),
                  isModuleAttached: new FormControl(item.isModuleAttached),
                })
              );
            }
          }
        })
      })
    this.updatedSelectedFilterSubModule = _.cloneDeep(this.selectedFilterSubModule)
    this.oldSelectedFilterSubModule = _.cloneDeep(this.subscriptionRes.result.users);
    this.activeSelectedFilterSubModule = _.cloneDeep(this.subscriptionRes.result.users);
    this.checkIsAdmin();
  }
  resetvalue() {
    if (this.userDetails.length == 0) {
      this.userDetails = [];
      this.users.controls.forEach(control => {
        control.get('isAdmin').setValue('');
        control.get('id').setValue('');
        control.get('firstName').setValue('');
        control.get('lastName').setValue('');
        control.get('email').setValue('');
      });
    }

  }


  getModules() {
    this.homePageService.onHeader(this.id).subscribe((res: any) => {
      const response = res;
      let capabilities = res.result.find((ele: any) => ele.headerLabel == "Capabilities")
      this.allModules = capabilities.modules;
      const getModules = response.result;
      for (const element of getModules) {
        if (element.modules.length) {
          this.mode = element.modules;
          for (const j of this.mode) {
            if (j.moduleName.length) {
              this.datamode.push(j);
            }
          }
        }
        this.modules = [...this.datamode];
      }
      
    });

    //error messages
    this.clearSubscription = this.utilService.SharingDataTextMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errorMessage = res;
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
      }
    );
  }

  private initForm() {
    this.subscriptionForm = new FormGroup({
      subscriptionId: new FormControl(0),
      subscriptionName: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      neverEndSubscription: new FormControl(this.neverEndSubscription),
      subscriptionModules: new FormArray([]),
      productPlan: new FormControl('Free'),
      price: new FormControl(0),
      customerName: new FormControl('', [Validators.required]),
      users: new FormArray([]),
    }, [checkUserEmail]);//userModuleRequired
    this.formlist = new Array(this.users.length).fill(0);
  }

  get users() {
    return this.subscriptionForm.get('users') as FormArray;
  }
  get usersNew() {
    return (this.subscriptionForm.get('users') as FormArray);
  }

  get userModules() {
    return this.subscriptionForm.get('userModules') as FormArray;
  }

  getControls() {
    return (this.subscriptionForm.get('users') as FormArray).controls;
  }

  get subscriptionModules() {
    return this.subscriptionForm.get('subscriptionModules') as FormArray;
  }
  isDateInCurrentMonth(givenDate: Date): boolean {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenYear = givenDate.getFullYear();
    return currentMonth === givenMonth && currentYear === givenYear;
  }
  startDate(event: any) {
    if (event.target.value !== null) {
      // this.neverEndSubFlag=true;
      const date = new Date(event.target.value);
      if (this.isDateInCurrentMonth(date)) {
        const formatedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
        this.createdFromStart = formatedDate;
        this.subscriptionForm.patchValue({
          startDate: formatedDate,
        });
      } else {
        this.subscriptionForm.get('startDate').setValue('')
      }
    } else {
      this.neverEndSubFlag = false;
      this.createdFromStart = null;
      this.subscriptionForm.patchValue({
        startDate: null,
      });
    }
    this.isModuleChanged = true;
  }
  setUpEndDate(event:any){
    this.neverEndSubFlag = false;
    const date = new Date(event.target.value);
    const formatedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    this.createdToEnd = formatedDate;
    this.subscriptionForm.patchValue({
      endDate: formatedDate,
    });
  }
  endDate(event: any) {
    if (event.target.value !== null) {
      let formattedDate = moment().format('YYYY-MM-DD');
      if (moment(event.target.value).isBefore(formattedDate)) {
        this.subscriptionForm.patchValue({
          endDate: null,
        });
        this.spinnerService.hide();
        this.errorMessage = 'End date should not be less than current date';
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
      } else if (moment(event.target.value).isAfter(formattedDate)) {
        this.setUpEndDate(event)
      } else {
        this.setUpEndDate(event)
      }
    } else {
      this.neverEndSubFlag = false;
      this.createdToEnd = null;
      this.subscriptionForm.patchValue({
        endDate: null,
      });
    }
    this.isModuleChanged = true;
  }


  isSubscriptionValid() {
    return this.selectedFilterModule.length > 0;
  }
  isUserScubscriptionValid() {
    let flag = false;
    if (this.selectedFilterSubModule.length > 0) {
      this.selectedFilterSubModule.forEach((el: any, i: any) => {
        if (el?.length == 0 || el == undefined) {
          flag = true
        }
      })
    } else {
      flag = true
    }
    return flag;
  }
  
  onAddNew() {
    (<FormArray>this.subscriptionForm.get('users')).push(
      new FormGroup({
        isAdmin: new FormControl(false),
        id: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$"), Validators.required, Validators.email]),
        userModules: new FormArray([]),
        statusId: new FormControl(''),
        statusName: new FormControl(''),
      })
    );

    this.checkIsAdmin();
  }

  loadApiModules(){
      this.homePageService.getApiHeaders(this.id).subscribe((apiRes: any) => {
        const apiResponse = apiRes;         
        const getApiModules = apiResponse.result;
        for (const apiElement of getApiModules) {
          if (apiElement.modules.length) {
            this.mode = apiElement.modules;
            for (const j of this.mode) {
              if (j.moduleName.length) {
                this.datamode.push(j);
                this.apiModules.push(j.moduleName);                
                if(this.apiUserGroups.length == 0 && j.userGroups.length > 0 ){                
                  this.apiUserGroups.push(j.userGroups[0]);                  
                }
              }
            }
          }
                    
          this.modules = [...this.datamode];            
        }
        this.utilService.setApiModules(this.apiModules);
        this.utilService.setApiUserGroups(this.apiUserGroups);
      });    
}


  onDeleteRow(index: number) {
    this.isModuleChanged = true;
    if (this.users.length > 1) {
      const user = (this.users.controls[index] as FormGroup).value;

      if (this.existedUserIds?.includes(user.id))
        this.isExistedUserDeleted = true;
      else
        this.isNewUserDeleted = true;

      (<FormArray>this.subscriptionForm.get('users')).removeAt(index);
      this.formlist.splice(index, 1);
      this.selectedFilterSubModule.splice(index, 1);

    }

    this.checkIsAdmin();
    this.subscriptionService.usersArray.next(this.users.value)
  }


  goback() {
    this.router.navigate(['/subscription']);
  }

  isSubmitEnabled(): boolean {
    return this.users.controls.some((group: any) => {
      return (
        group.get('firstName')?.value === '' ||
        group.get('lastName')?.value === '' ||
        group.get('email')?.value === ''
      );
    });

  }

  isFormEdited(): boolean {
    return this.subscriptionForm.dirty || this.isSubscriptionModified || this.isExistedUserDeleted || this.isModuleChanged;
  }

  onModuleSelect(item: any) {
    this.isModuleChanged = true
    if (this.subscriptionId > 0) {
      this.subscriptionModuleChange();
    } else {
      (<FormArray>this.subscriptionForm.get('subscriptionModules')).push(
        new FormGroup({
          id: new FormControl(item.id, [Validators.required]),
          moduleName: new FormControl(item.moduleName, [Validators.required]),
          isModuleAttached: new FormControl(true),
        })
      );
      this.subModule = [...this.selectedFilterModule];
    }
  }

  onModuleDeselect(item: any) {
    if (this.subscriptionId > 0) {
      this.subscriptionModuleChange()
    } else {
      this.selectedFilterSubModule.forEach((element: any, index: any) => {
        element.forEach((ele: any, i: any) => {
          if (ele.id == item.id) {
            element.splice(i, 1)
          }
        })
      })

    }

    this.users.value.forEach((user: any) => {
      user.userModules.forEach((module: any) => {
        let index = user.userModules.findIndex((ele: any) => ele.id == item.id)
        if (index > -1) {
          user.userModules.splice(index, 1)
        }
      })
    })
    this.isModuleChanged = true
    this.subscriptionService.usersArray.next(this.users.value)
    this.subModule = [...this.selectedFilterModule];
  }

  subscriptionModuleChange() {
    if (this.selectedFilterModule.length > 0) {
      const results = this.allSubscription.filter((x: any) => !this.selectedFilterModule.some((y: any) => y.id === x.id));
      this.filtertedSubscriptionWhichAreRemoved = results.filter((x: any) => x.isModuleAttached == true);
    }
    this.updatedSelectedFilterSubModule = _.cloneDeep(this.selectedFilterSubModule);
    this.subModule = _.cloneDeep(this.selectedFilterModule);
    this.selectedFilterSubModule.forEach((item: any, i: any) => {
      item.forEach((element: any, index: any) => {
        let val = this.subModule.findIndex((el: any) => el.id == element.id)
        if (val === -1) {
          this.selectedFilterSubModule[i].splice(index, 1);
        }
      });
    })
    this.selectedFilterSubModule.forEach((element: any, i: any) => {
      this.selectedFilterSubModule[i] = [...element];
    });
  }

  onSubModuleSelect(item: any, index: number) {
    this.isModuleChanged = true;
    const isApiModule = this.apiModules.includes(item.moduleName);    

    if (this.usersArrayCopy[index]) {
      let idx = this.users.value[index].userModules.findIndex((ele: any) => ele.id == item.id);
      if (idx==-1) {
        this.users?.value[index]?.userModules?.push(
          {
            ...item,
            isModuleAttached: true,
            userGroups: []
          }
        )
      } else {
        this.users.value[index].userModules[idx].isModuleAttached=true;
        this.users.value[index].userModules[idx].userGroups=[];
      }

    } else {
      this.users.value[index].userModules.push({
        ...item,
        isModuleAttached: true,
        userGroups: []
      })
    }


    this.subscriptionService.usersArray.next(this.users.value)
    if(isApiModule){
      this.showUGPopup(index);
      this.onChangeGroup(this.apiUserGroups[0]);
      this.assignGroups();
    }
  }
  onSubModuleDeselect(item: any, index: number) {
    this.isModuleChanged = true;
    this.users.value[index].userModules = [
      ...this.usersArrayCopy[index]?.userModules
    ]
    let i = this.users.value[index].userModules.findIndex((ele: any) => ele.id == item.id)
    this.users.value[index].userModules[i].isModuleAttached = false
    this.users.value[index].userModules[i].userGroups = []
    this.subscriptionService.usersArray.next(this.users.value)
  }

  onCancel() {
    this.isCancelActive = true;
  }

  onClose() {
    this.isCancelActive = false;
  }

  onResetForm() {
    this.isCancelActive = false;
    this.isSubscriptionModified = false;
    this.subscriptionForm.markAsPristine();
    if (!this.subscriptionId) {
      this.subscriptionForm.reset({
        subscriptionId: 0,
        subscriptionName: '',
        startDate: '',
        endDate: '',
        neverEndSubscription: false,
        subscriptionModules: [],
        productPlan: 'Free',
        price: 0,
        customerName: '',
      });
      (<FormArray>this.subscriptionForm.get('subscriptionModules')).clear();
      (<FormArray>this.subscriptionForm.get('users')).clear();
      this.onAddNew();
      this.selectedFilterModule = [];
      this.selectedFilterSubModule = [];
      this.userDetails = [];
      this.filterUserDetails = [];
      this.isNewUserDeleted = false;
      this.isModuleChanged = false;
    } else {
      this.deletedUsers = [];
      this.isSubscriptionModified = false;
      this.isExistedUserDeleted = false;
      this.isNewUserDeleted = false;
      this.isModuleChanged = false;
      this.loadSubscriptionDetailsOnEdit();
    }
  }


  onSubmit() {
    this.spinnerService.show();
    this.reviewForm();
    this.createSubscriptionService
      .createSubscription(this.subscriptionForm.value)
      .subscribe({
        next: (res: any) => {
          this.spinnerService.hide();
          if (res.code !== 200) {
            const errorResult = res.result;
            this.errorMessage = errorResult.error;
            setTimeout(() => {
              this.errorMessage = null;
            }, 3000);
          } else {
            const response = res;
            this.successMessage = response.message;
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;
              this.router.navigate(['/subscription']);
            }, 3000);
          }
        },
        error: (err) => {
          this.spinnerService.hide();
        },
      });
  }

  checkDeleteButtonCriteria(index: any) {
    if (index == 0 && this.userType == 'Subscription Admin')
      return true;
    else return false;
  }

  checkReadOnly() {
    const userType = localStorage.getItem('userType');
    if (userType == 'Subscription Admin')
      return false;
    if (userType == 'DMINT Admin')
      return true;
    else return false;
  }

  reviewForm() {
    this.users.controls.forEach(user => {
      const fn = user.get('firstName').value;
      const ln = user.get('lastName').value;
      if (typeof fn == 'object')
        user.get('firstName').setValue(fn.firstName);
      if (typeof ln == 'object')
        user.get('lastName').setValue(ln.lastName);
    });
  }

  onEditSave() {
    this.spinnerService.show();
    this.reviewForm();
    let subscriptionModules = [...(this.selectedFilterModule.map((x: any) => ({ ...x, isModuleAttached: true }))), ...(this.filtertedSubscriptionWhichAreRemoved.map((x: any) => ({ ...x, isModuleAttached: false })))]
    let payload = {
      ...this.subscriptionForm.value,
      subscriptionModules: subscriptionModules,
      users: this.users.value
    }
    payload.endDate = new Date(payload.endDate).toISOString();
    payload.startDate = new Date(payload.startDate).toISOString();
    this.createSubscriptionService
      .updateSubscription(payload)
      .subscribe({
        next: (res: any) => {
          this.spinnerService.hide();
          if (res.code !== 200) {
            const errorResult = res.result;
            this.errorMessage = errorResult.error;
            setTimeout(() => {
              this.errorMessage = null;
            }, 3000);
          } else {
            const response = res;
            this.successMessage = response.message;
            this.utilService.goToTop();
            setTimeout(() => {
              this.successMessage = null;
              this.router.navigate(['/subscription']);
            }, 3000);
          }
        },
        error: (err) => {
          this.spinnerService.hide();
        },
      });
  }

  trackValueChange(controlName: any) {
    if (this.subscriptionForm.get(controlName).dirty)
      this.isModuleChanged = true;
  }

  checkEmail(event: any, i: any) {
    console.log(event.target.value)
  }
  checkNeverendSub(event: any) {
    this.isModuleChanged = true
    if (event.target.checked == true) {
      this.neverEndSubFlag = true
      var date = new Date(this.subscriptionForm.get('startDate').value);
      var amountOfYearsRequired = 3;
      this.subscriptionForm.get('endDate').patchValue(new Date(date.setFullYear(date.getFullYear() + amountOfYearsRequired)))
    } else {
      this.neverEndSubFlag = false
      var date = new Date(this.subscriptionForm.get('startDate').value);
      this.subscriptionForm.get('endDate').patchValue(new Date(date.setFullYear(date.getFullYear())))
    }
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }
  disableCustomerField(e: any) {
    e.stopPropagation();
    this.auto.close()
  }

  checkIsAdmin() {
    let indexArr = this.users.value.filter((user: any) => user.isAdmin == true)
    if (indexArr.length == 0) {
      this.noAdminPresent = true;
    } else {
      this.noAdminPresent = false;
    }
  }
  checkEmailValidation() {
    let pattern = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$');
    let userArr = this.users.value?.filter((user: any) => !pattern.test(user.email))
    if (userArr.length > 0) return true;
    return false;
  }
  checkDuplicateEmail() {
    let matchedEmail: any = [];
    this.users.value?.forEach((user: any) => {
      let emailArray = this.users.value.filter((val: any) => val.email == user.email);
      if (emailArray.length > 1) {
        matchedEmail = [...matchedEmail, ...emailArray]
      }
    });
    if (matchedEmail.length > 0) {
      this.isDuplicateEmail = true;
    } else {
      this.isDuplicateEmail = false;
    }

  }

  /** assign user group to users code start */
  showUGPopup(index: any) {
    this.setUsers()
    this.modulePopupFlag = true         // to show popup
    this.currentUserId = this.users?.value[index]?.id
    this.totalGroupAssignedCount = 0
    let tabs = this.filterIsattachedModules(index) // filter the modules on tab which are assigned to users
    this.chips = _.cloneDeep(tabs);     // show only those modules on tab which are assigned to users
    this.activateTabs()                 // show the tabs
    this.showActiveTabModules(index)    // show all groups of active 
    this.selectGroupsWhichAreChecked()  // select groups for which isAssigned is true
    this.addActiveTabsToaService()      // this store active group info in a service veriable
    this.allCheckedgroups()             // this is use to show groups which are checked
    this.countTotalGroupsAssigned()     // this gives total number of group assinged to a user
  }
  ngDoCheck() {
    this.setUsers()
  }
  setUsers() {
    this.users.value.forEach((item: any) => {
      let index = this.usersArrayCopy.findIndex((ele: any) => ele.id == item.id)
      if (index > -1) {
        item.userModules = _.cloneDeep(this.usersArrayCopy[index].userModules)
      }
    })
  }
  switchTab(id: any) {
    this.searchText = ""
    this.showOtherSelectedGroupsFlag = false
    let index = this.chips.findIndex((item: any) => item.id == id);
    let index2 = this.activeTabModules.findIndex((item: any) => item.id == id);
    this.activeTabUserGroups = this.activeTabModules[index2].userGroups;
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
    this.subscriptionService.activeUserGroup.next(this.activeTabUserGroups)
    if (index > -1) { this.activateTabs(index) }
  }
  allCheckedgroups() {
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
  }
  countTotalGroupsAssigned() {
    this.chips.forEach((item: any) => {
      let count = item?.userGroups?.filter((ele: any) => ele.isAssigned == true)
      if (count?.length > 0) {
        this.totalGroupAssignedCount += count.length;
      }
    })
  }
  addActiveTabsToaService() {
    this.subscriptionService.activeUserGroup.next(this.activeTabUserGroups)
  }
  showActiveTabModules(index: any) {
    this.tempModules = _.cloneDeep(this.modules)
    this.tempModules.forEach((item: any) => {
      const isApiModule = this.apiModules.includes(item.moduleName);

      if (item.userGroups?.length > 0) {
        item.userGroups.forEach((ele: any) => {
          ele.isAssigned = isApiModule ? true : false;
        })
      }
    })
    this.tempModules.forEach((element: any) => {
      if (element?.userGroups?.length > 0) {
        let idx = this.usersArrayCopy[index]?.userModules?.findIndex((ele: any) => ele.id == element.id);
        if (idx > -1) {
          element?.userGroups?.forEach((item: any) => {
            let i = this.usersArrayCopy[index]?.userModules?.findIndex((el: any) => el.id == element.id)
            if (i > -1) {
              let i2 = this.usersArrayCopy[index]?.userModules[i]?.userGroups?.findIndex((x: any) => x.id == item.id)
              if (i2 > -1) {
                item.isAssigned = true;
              }
            }
          })
        }
      }
    });

  }
  selectGroupsWhichAreChecked() {
    let filterCriteria = this.chips.map((ele: any) => ({ id: ele.id }));
    this.activeTabModules = this.tempModules.filter((ele: any) => filterCriteria.some((criteria: any) => criteria.id == ele.id))
    this.chips.forEach((ele: any) => {
      let module = this.tempModules.find((item: any) => item.id == ele.id)
      if (module?.userGroups?.length > 0) {
        ele.userGroups = module.userGroups
      } else {
        ele.userGroups = []
      }
    })
    this.activeTabUserGroups = this.chips[0].userGroups;
  }
  filterIsattachedModules(index: any) {
    // return this.users.value[index].userModules.filter((element:any)=>element.isModuleAttached==true)
    return this.selectedFilterSubModule[index]
  }
  activateTabs(index = 0) {
    this.chips.forEach((item: any, i: any) => {
      if((i == index)){
        this.chips[i].selected = true;
        this.selectedChip = this.chips[i].moduleName;
      } else  this.chips[i].selected = false;
    
    });

  }
  closePopup() {
    this.modulePopupFlag = false
    this.activeTabModules = []
    this.activeTabUserGroups = []
    this.subscriptionService.activeUserGroup.next([])
    this.searchText = "";
    this.currentUserId = '';
  }
  filterUserGroups() {
    if (this.searchText != "") {
      let groups = _.cloneDeep(this.activeTabUserGroupsCopy)
      this.activeTabUserGroups = groups.filter((item: any) => item.userGroupName?.toLowerCase()?.includes(this.searchText.toLowerCase()))
    } else {
      this.activeTabUserGroups = _.cloneDeep(this.activeTabUserGroupsCopy)
    }
  }
  onChangeGroup(group: any) {
    group.isAssigned = !group.isAssigned;
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
    let counter = 0
    this.chips.forEach((item: any) => {
      let count = item?.userGroups?.filter((ele: any) => {
        return ele.isAssigned == true;
      })
      if (count?.length > 0) {
        counter += count.length;
      }
    })

    this.totalGroupAssignedCount = counter;
  }

  get visibleChips() {
    return this.chips.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
  }
  next() {
    if (this.startIndex + this.itemsPerSlide < this.chips.length) {
      this.startIndex += this.itemsPerSlide;
    }
  }
  prev() {
    if (this.startIndex > 0) {
      this.startIndex -= this.itemsPerSlide;
    }
  }
  showOtherSelectedGroups() {
    this.showOtherSelectedGroupsFlag = !this.showOtherSelectedGroupsFlag;
  }
  uncheckGroup(groupId: any) {
    let index = this.activeTabUserGroups.findIndex((item: any) => item.id == groupId);
    if (index > -1) {
      this.activeTabUserGroups[index].isAssigned = false
    }
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
  }
  removeAllSelectedGropus() {
    this.activeTabUserGroups.forEach((item: any) => {
      item.isAssigned = false;
    })
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
  }
  assignGroups() {

    let index = this.users.value.findIndex((ele: any) => ele.id == this.currentUserId);
    if (index > -1) {
      this.users.value[index].userModules.forEach((item: any, indx: any) => {
        let module = this.tempModules.find((x: any) => x.id == item.id);
        if (module?.userGroups?.length > 0) {
          let userGrp = []
          userGrp = module.userGroups.filter((e: any) => e.isAssigned == true)
          item.userGroups = userGrp;
        }
      })
    }
    this.subscriptionService.usersArray.next(this.users.value)
    this.isModuleChanged = true
    this.closePopup();
    this.checkMinimumGroupCount();
  }
  disableUserGroupButton(i: any) {
    if (this.selectedFilterSubModule[i] && this.selectedFilterSubModule[i].length > 0) {
      return false
    } else {
      return true
    }
  }
  checkUserGroupCount(i: any) {
    if (this.selectedFilterSubModule[i] && this.selectedFilterSubModule[i].length > 0) {
      return true;
    } else {
      return false;
    }
  }
  getGroupAssignedCount(index: any) {
    let count = 0
    this.usersArrayCopy[index]?.userModules.forEach((item: any, i: any) => {
      if (item?.isModuleAttached == true) {
        count += item?.userGroups?.length || 0
      }
    })
    return count;
  }
  clearText() {
    this.searchText = "";
    this.activeTabUserGroups = _.cloneDeep(this.activeTabUserGroupsCopy)
  }
  checkSearchIcon() {
    return this.searchText?.length > 0
  }
  checkMinimumGroupCount() {
    let groupCountFlag = false;
    this.users.value.forEach((ele: any) => {
      if (ele?.userModules?.length > 0) {        
        ele?.userModules.forEach((item: any) => {
          if (item.isModuleAttached == true && item.userGroups.length == 0) {
            groupCountFlag = true;
          }
        })
      }
    })
    return groupCountFlag;
  }
  /** assign user group to users code end */
  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.showOtherSelectedGroupsFlag = false;
    }
  }
}

function onlyUnique(value: any, index: any, array: any) {
  return array.indexOf(value) === index;
}
