import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ManageSubscriptionService } from './manage-subscription.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiUrlService } from 'src/app/shared/services/url.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  subscriptionForm!: FormGroup;
  id: string = '';
  files: any;
  public fileData: any;
  filterData: any = [];
  totalItems: any;
  p: number = 1;
  currentPage: any;
  itemsPerPage = 10;
  error: any;
  errorMessage: string = 'No subscriptions to show here';
  selectedRowId: any;
  customerRes: any;
  public customerData: any;
  customers: any = [];
  flagError=false;
  statusRes: any;
  public statusData: any;
  status: any = [];

  dateFilterOptions: any = ['Starts on', 'Ends on'];
  selectedDateButtonIndex: number = null;

  selectedCustomerType: any = [];
  customerFilterDropdownSettings: IDropdownSettings = {};

  selectedStatusType: any = [];
  statusFilterDropdownSettings: IDropdownSettings = {};

  selectedFilterPeriod: any = [];
  periodFilterDropdownSettings: IDropdownSettings = {};
  periods: any = [];
  isSubscriptionAdmin=false;
  activeStatus:any=[];
  activePeriod:any=[];
  startsOn = [
    {id:1, period_name: 'current_week', period_text: 'Current Week' },
    {id:2, period_name: 'current_month', period_text: 'Current Month' },
    {id:3, period_name: 'last_month', period_text: 'Last Month' },
    {id:4, period_name: 'last_2_months', period_text: 'Last 2 Months' },
    {id:5, period_name: 'last_3_months', period_text: 'Last 3 Months' },
    {id:6, period_name: 'last_6_months', period_text: 'Last 6 Months' },
    {id:7, period_name: 'last_year', period_text: 'Last Year' },
    {id:8, period_name: '2022', period_text: '2022' },
    {id:9, period_name: '2021', period_text: '2021' },
    {id:10, period_name: '2020', period_text: '2020' },
    {id:11, period_name: '2019', period_text: '2019' },
    {id:12, period_name: '2018', period_text: '2018' },
    {id:13, period_name: '2017', period_text: '2017' },
  ];

  EndsOn = [
    {id:1, period_name: 'current_week', period_text: 'Current Week' },
    {id:2, period_name: 'current_month', period_text: 'Current Month' },
    {id:3, period_name: 'last_month', period_text: 'Last Month' },
    {id:4, period_name: 'last_2_months', period_text: 'Last 2 Months' },
    {id:5, period_name: 'last_3_months', period_text: 'Last 3 Months' },
    {id:6, period_name: 'last_6_months', period_text: 'Last 6 Months' },
    {id:7, period_name: 'last_year', period_text: 'Last Year' },
    {id:8, period_name: 'next_month', period_text: 'Next Month' },
    {id:9, period_name: 'next_2_months', period_text: 'Next 2 Months' },
    {id:10, period_name: 'next_3_months', period_text: 'Next 3 Months' },
    {id:11, period_name: 'next_6_months', period_text: 'Next 6 Months' },
    {id:12, period_name: 'next_year', period_text: 'Next Year' },
    {id:13, period_name: '2030', period_text: '2030' },
    {id:14, period_name: '2029', period_text: '2029' },
    {id:15, period_name: '2028', period_text: '2028' },
    {id:16, period_name: '2027', period_text: '2027' },
    {id:17, period_name: '2026', period_text: '2026' },
  ];

  isPeriodDisabled: boolean = true;

  statusTypeId: number = 2;
  isDeactivatePopupVisible: boolean = false;
  storeSubscriptionName: string = '';
  storeSubscriptionId: number;
  responseResult: string = null;
  isAdvancedFilter: boolean = false;
  customerIds: any = [];
  statusIds: any = [];
  periodForValue: string = '';
  period: string = '';
  isAscendingCreated: boolean = true;
  isAscendingCompleted: boolean = true;
  sortByValue: string = '';
  sortTypeValue: string = 'desc';
  sortColumn: string = '';
  searchValue: any = '';
  bgColorStatus: boolean = false;
  contactId: any = [];
  clearSubscription: Subscription;
  errMsgPopup: any;
  errMsgText: string = null;
  filterValues: any;
  deleteParameters: any;
  isDeletePopupVisible: boolean = false;
  recSubscriptionName: string = '';
  recSubscriptionId: number;
  deleteTextSwap: boolean = false;
  deleteText: string = 'Yes, Delete';
  userType: string;
  storeStatus: string = '';
  seacrchForm:FormGroup
  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private fb: FormBuilder,
    private subscriptionService: ManageSubscriptionService,
    private spinnerService: NgxSpinnerService,
    private apiUrlService: ApiUrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.utilService.getUserId.subscribe((id: any) => {
      this.id = id as string;
    });
    if (localStorage.getItem('userId')) {
      this.id = localStorage.getItem('userId');
      this.userType = localStorage.getItem('userType');
      if(this.userType=='Subscription Admin'){
        this.isSubscriptionAdmin=true;
      }else{
        this.isSubscriptionAdmin=false;
      }
    }

    this.buildForm();
    this.seacrchForm=new FormGroup({searchText:new FormControl('')})
    // customer Search settings
    this.customerFilterDropdownSettings = {
      singleSelection: false,
      idField: 'companyId',
      textField: 'companyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };

    // status Search settings
    this.statusFilterDropdownSettings = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };

    // period search setting
    this.periodFilterDropdownSettings = {
      singleSelection: true,
      idField: 'period_name',
      textField: 'period_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.clearSubscription = this.utilService.SharingDataTextMessage.subscribe(
      (res: any) => {
        this.spinnerService.hide();
        this.errorMessage = res;
      }
    );

    this.fetchCustomers();
    this.fetchStatus();
    this.fetchAllSubscription();
  }

  fetchCustomers() {
    this.subscriptionService.getCustomersByUser(this.id).subscribe(
      {
      next: (res: any) => {
        if (res.code !== 200) {
          console.log('error');
        } else {
          this.customerRes = res;
          this.customerData = this.customerRes.result;
          this.customers = [...this.customerData];
        }
      },
      error:(err)=>{
        this.spinnerService.hide()
          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.errorMessage = res
          })
          this.flagError = true;
          setTimeout(() => { this.flagError = false; this.errorMessage = '';this.router.navigate(['/home']) }, 5000)
      }
    });
  }

  fetchStatus() {
    this.subscriptionService.getAllStatus(this.statusTypeId).subscribe({
      next: (res: any) => {
        if (res.code !== 200) {
          console.log('error');
        } else {
          this.statusRes = res;
          this.statusData = this.statusRes.result;
          this.status = [...this.statusData];
        }
      },
    });
  }

  fetchAllSubscription() {
    this.subscriptionService.fetchAllSubscriptionRecord(this.id).subscribe({
      next: (res: any) => {
        if (res.message !== 'OK') {
          console.log('error part');
          this.spinnerService.hide();
        } else {
          console.log(res);
          this.files = res;
          this.fileData = this.files.result;
          this.filterData = [...this.fileData];
          console.log(this.filterData);
          this.totalItems = this.files.totalCount;
          this.spinnerService.hide();
        }
      },
      error: (err) => {
        this.spinnerService.hide();
      },
    });
  }

  buildForm() {
    this.subscriptionForm = this.fb.group({
      customer: new FormArray([]),
      status: new FormArray([]),
      period: new FormControl(),
      periodFor: new FormControl(this.periodForValue),
      searchText: new FormControl(this.searchValue),
    });
  }

  getPage(event: any) {
    this.spinnerService.show();
    this.p = event;
    this.currentPage = this.p - 1;
    const userId: any = this.id.toString();
    const apiUrl = this.apiUrlService.getApiUrl();
    if (!this.isAdvancedFilter) {
      this.http
        .post(
          `${apiUrl}/v1/subscriptions?limit=${this.itemsPerPage}&page=${this.p}`,
          { userId }
        )
        .subscribe({
          next: (res: any) => {
            this.spinnerService.hide();
            if (res.message !== 'OK') {
              console.log('error part');
            } else {
              console.log(res);
              this.files = res;
              this.fileData = this.files.result;
              this.filterData = [...this.fileData];
              this.totalItems = this.files.totalCount;
              console.log(this.totalItems);
            }
          },
          error: (err) => {
            console.log(err);
            this.spinnerService.hide();
          },
        });
    } else if (this.isAdvancedFilter) {
      this.http
        .post(
          `${apiUrl}/v1/subscriptionDetailsFilter?limit=${this.itemsPerPage}&page=${this.p}`,
          this.filterValues
        )
        .subscribe({
          next: (res: any) => {
            this.spinnerService.hide();
            if (this.files.message === 'OK') {
              this.files = res;
              this.fileData = this.files.result;
              this.filterData = [...this.fileData];
              this.totalItems = this.files.totalCount;
            }
          },
          error: (err: any) => {
            this.spinnerService.hide();
            this.totalItems = null;
          },
        });
    }
  }

  onKebabMenuClick(index: any) {
    if (this.selectedRowId === index) {
      this.selectedRowId = null;
    } else {
      this.selectedRowId = index;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.tr')) {
      this.selectedRowId = null;
    }
  }

  onDateOptionSelect(index: number) {
    this.selectedDateButtonIndex = index;
    this.periods = [];
    this.activePeriod=[];
    if (index === 0) {
      this.periodForValue = 'startsOn';
      this.period = '';
      this.selectedFilterPeriod = null;
      this.periods = this.startsOn;
    } else {
      this.period = '';
      this.periodForValue = 'endsOn';
      this.selectedFilterPeriod = null;
      this.periods = this.EndsOn;
    }
    this.isPeriodDisabled = false;
    this.onAdvancedFilter();
  }

  onDeactivate(subName: string, subId: number, status: string) {
    this.storeSubscriptionName = subName;
    this.storeSubscriptionId = subId;
    if (status === 'INACTIVE') {
      this.storeStatus = 'active';
    } else {
      this.storeStatus = 'inactive';
    }
    this.isDeactivatePopupVisible = true;
    this.selectedRowId = null;
  }

  onDeactivateConfirm() {
    this.spinnerService.show();
    this.subscriptionService
      .deactivateSubscription(
        this.id,
        this.storeSubscriptionId,
        this.storeStatus.toLowerCase()
      )
      .subscribe({
        next: (res: any) => {
          this.spinnerService.hide();
          this.onClose();
          this.getPage(this.p);
          this.responseResult = res.message;
          setTimeout(() => {
            this.responseResult = null;
          }, 3000);
          this.storeStatus = '';
        },
      });
  }

  onClose() {
    this.isDeactivatePopupVisible = false;
    this.storeSubscriptionName = '';
    this.storeSubscriptionId = null;
    this.storeStatus = '';
  }

  onCustomerSelect(item: any) {
    const control = new FormControl(item.companyId);
    (<FormArray>this.subscriptionForm.get('customer')).push(control);
    this.subscriptionForm.patchValue({
      customer: this.selectedCustomerType.companyId,
    });
    let data = this.selectedCustomerType;
    this.customerIds = data.map((item: any) => {
      return item.companyId;
    });
    this.onAdvancedFilter();
  }

  onCustomerDeselect(item: any) {
    let i = 0;
    (<FormArray>this.subscriptionForm.get('customer')).controls.forEach(
      (l: any) => {
        if (l.value == item.companyId) {
          (<FormArray>this.subscriptionForm.get('customer')).removeAt(i);
          this.customerIds.splice(i, 1);
          return;
        }
        i++;
      }
    );
    this.onAdvancedFilter();
  }

  onSelectAllCustomers(items: any) {
    (<FormArray>this.subscriptionForm.get('customer')).clear();
    this.customerIds = [];
    for (let key of items) {
      const controls = new FormControl(key.companyId);
      (<FormArray>this.subscriptionForm.get('customer')).push(controls);
      this.customerIds.push(key.companyId);
    }
    this.onAdvancedFilter();
  }

  onDeSelectAllCustomers() {
    (<FormArray>this.subscriptionForm.get('customer')).clear();
    this.customerIds = [];
    this.onAdvancedFilter();
  }

  onStatusSelect(item: any) {
    const control = new FormControl(item.statusId);
    (<FormArray>this.subscriptionForm.get('status')).push(control);
    this.subscriptionForm.patchValue({
      status: this.selectedStatusType.statusId,
    });
    let data = this.selectedStatusType;
    this.statusIds = data.map((item: any) => {
      return item.statusId;
    });

    this.setFilters();
    this.onAdvancedFilter();
  }
  setFilters(){
    this.activeStatus=this.status.filter((item:any)=>this.statusIds.indexOf(item.statusId) !== -1)
  }
  removePeriod(i:any,item:any){
    this.onPeriodDeselect(item);
    this.activePeriod=[];
    this.selectedFilterPeriod=[]
  }
  removeStatus(i:any,item:any){
    this.activeStatus.splice(i, 1);
    (<FormArray>this.subscriptionForm.get('status')).removeAt(i);
    this.statusIds.splice(i, 1);
    this.onStatusDeselect(item)
    this.selectedStatusType=this.selectedStatusType.filter((x:any)=>x.statusId!=item.statusId)
  }
  onStatusDeselect(item: any) {
    let i = 0;
    (<FormArray>this.subscriptionForm.get('status')).controls.forEach(
      (l: any) => {
        if (l.value == item.statusId) {
          (<FormArray>this.subscriptionForm.get('status')).removeAt(i);
          this.statusIds.splice(i, 1);
          return;
        }
        i++;
      }
    );
    this.setFilters();
    this.onAdvancedFilter();
  }

  onSelectAllStatus(items: any) {
    (<FormArray>this.subscriptionForm.get('status')).clear();
    this.statusIds = [];
    for (let key of items) {
      const controls = new FormControl(key.statusId);
      (<FormArray>this.subscriptionForm.get('status')).push(controls);
      this.statusIds.push(key.statusId);
    }
    this.setFilters();
    this.onAdvancedFilter();
  }

  onDeSelectAllStatus() {
    (<FormArray>this.subscriptionForm.get('status')).clear();
    this.statusIds = [];
    this.setFilters();
    this.onAdvancedFilter();
  }

  onPeriodSelect(item: any) {
    this.period = item.period_name;
    this.onAdvancedFilter();
    this.setPeriod(item);
  }

  onPeriodDeselect(item: any) {
    this.period = '';
    this.activePeriod=[];
    this.onAdvancedFilter();
  }

  onSort(column: string) {
    this.sortColumn = column;
    if (this.sortColumn === 'start_date') {
      this.isAscendingCreated = !this.isAscendingCreated;
      this.sortByValue = 'start_date';
      if (this.isAscendingCreated === true) {
        this.sortTypeValue = 'desc';
      } else {
        this.sortTypeValue = 'asc';
      }
    } else {
      this.isAscendingCompleted = !this.isAscendingCompleted;
      this.sortByValue = 'end_date';
      if (this.isAscendingCompleted === true) {
        this.sortTypeValue = 'desc';
      } else {
        this.sortTypeValue = 'asc';
      }
    }
    this.onAdvancedFilter();
  }

  getSearchText(e: any) {
    this.searchValue = e.target.value;
    this.onAdvancedFilter();
  }

  setPeriod(item:any){
    this.activePeriod[0]=item;
  }

  onAdvancedFilter() {
    this.spinnerService.show();
    this.isAdvancedFilter = true;
    if (this.searchValue === null) {
      this.searchValue = '';
    }
    this.filterValues = {
      userId: this.id.toString(),
      contactId: this.contactId,
      customerId: this.customerIds,
      statusId: this.statusIds,
      periodFor: this.periodForValue,
      period: this.period,
      searchText: this.searchValue,
      sortBy: this.sortByValue,
      sortType: this.sortTypeValue,
    };
    this.p = 1;
    this.subscriptionService.getFilterRecords(this.filterValues).subscribe({
      next: (res: any) => {
        this.spinnerService.hide();
        if (this.files.message === 'OK') {
          this.files = res;
          this.fileData = this.files.result;
          this.filterData = [...this.fileData];
          this.totalItems = this.files.totalCount;
        }
      },
      error: (err: any) => {
        this.spinnerService.hide();
        this.totalItems = null;
      },
    });
  }

  onReset() {
    this.spinnerService.show();
    this.isAdvancedFilter = false;
    this.subscriptionForm.get('searchText')?.reset();
    this.searchValue = '';
    this.subscriptionForm.patchValue({
      searchText: '',
    });
    this.subscriptionForm.get('periodFor')?.reset();
    this.subscriptionForm.get('period')?.reset();
    (<FormArray>this.subscriptionForm.get('customer')).clear();
    (<FormArray>this.subscriptionForm.get('status')).clear();
    this.selectedCustomerType = [];
    this.selectedStatusType = [];
    this.customerIds = [];
    this.statusIds = [];
    this.isPeriodDisabled = true;
    this.periodForValue = '';
    this.selectedDateButtonIndex = null;
    this.isAscendingCreated = true;
    this.isAscendingCompleted = true;
    this.sortByValue = '';
    this.sortTypeValue = '';
    this.totalItems = null;
    this.searchValue = '';
    this.storeStatus = '';
    this.periods=[]
    this.subscriptionForm.patchValue({
      searchText: '',
    });
    this.seacrchForm.patchValue({
      searchText: '',
    });
    this.p = 1;
    this.fetchAllSubscription();
    this.selectedFilterPeriod = [];
    this.activePeriod=[]
    this.activeStatus=[]
  }

  onDelete(recSubName: string, recSubId: number) {
    this.recSubscriptionName = recSubName;
    this.recSubscriptionId = recSubId;
    this.isDeletePopupVisible = true;
    this.deleteTextSwap = true;
  }

  onCloseDelete() {
    this.isDeletePopupVisible = false;
    this.deleteTextSwap = false;
    this.deleteText = 'Yes, Delete';
  }

  onDeleteConfirm() {
    this.deleteText = this.deleteTextSwap ? 'Deleting...' : 'Yes, Delete';
    this.deleteParameters = {
      userId: this.id.toString(),
      subscriptionId: this.recSubscriptionId,
    };
    this.subscriptionService
      .deleteSubscription(this.deleteParameters)
      .subscribe({
        next: (res: any) => {
          if (res.code !== 200) {
            console.log(res);
            this.spinnerService.hide();
            this.deleteTextSwap = false;
            this.deleteText = 'Yes, Delete';
          } else {
            this.isDeletePopupVisible = false;
            this.responseResult = res.message;
            this.p = 1;
            if(this.searchValue!=''){
              this.onAdvancedFilter()
            }else{
              this.fetchAllSubscription(); 
            }
            setTimeout(() => {
              this.responseResult = null;
            }, 4000);
            this.deleteTextSwap = false;
            this.deleteText = 'Yes, Delete';
          }
        },
        error: (err: any) => {
          this.spinnerService.hide();
          this.deleteTextSwap = false;
          this.deleteText = 'Yes, Delete';
        },
      });
  }

  onCreateNew() {
    this.router.navigate(['/create-subscription']);
  }

  onEdit(subscriptionId: number) {
    this.router.navigate(['/edit-subscription', subscriptionId]);
    this.utilService.goToTop();
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
    this.isDeletePopupVisible = false;
    this.responseResult = null;
  }
}
