<div class="analyticsPage">
    <!-- <lib-app-header></lib-app-header> -->
    <div class="wrpr">
        <div class="contentCard">
            <div class="pageHead">
                <img src="../../../../assets/images/analyticsIcon.svg" alt="analytics-icon">
                <h1>Analytics</h1>
            </div>
            <!-- <div class="tabsCont">
                <div class="tabs">
                    <div class="tab" 
                        (click)="selectTab(i)"
                        *ngFor="let tab of tabs; let i = index"
                        [class.active]="activeTab === i"
                        >
                        {{tab}}
                    </div>
                </div>
            </div> -->
            <div class="tabContent">
                <input type="search" [(ngModel)]="searchTerm" class="search inputField" placeholder="Search">
                <div class="filtersCont">
                    <div class="sectionHead">
                        <img src="../../../../assets/images/filterIcon.svg" alt="filters-icon">
                        <h4>Filters</h4>
                    </div>
                    <div class="filterRow">
                        <div class="formControl type">
                            <label 
                                for="type"
                                class="formLabel">
                                Type
                            </label>
                            <button class="toggleCont" (click)="showOptions()">
                                <p *ngIf="selectedItems.length === 0">Select</p>
                                <span *ngIf="selectedItems.length" class="optionSelected" title="{{ getSelectedNames().join(', ') }}">
                                    {{ getSelectedNames().join(', ') }}
                                  </span>
                                <span *ngIf="selectedItems.length" class="clrBtn" (click)="clearSelection()">Clear</span>
                                <img src="../../../../assets/images/chevron-south.svg" alt="chevron-south">
                            </button>
                            <div class="optionsCont" *ngIf="isDropdownOpen">
                                <label for="{{option.name}}" 
                                    class="option" *ngFor="let option of typeOptions">
                                        <input type="checkbox" id="{{option.name}}" 
                                        [checked]="isSelected(option.id)"
                                        (change)="toggleSelection(option.id)">
                                        <span class="styledCheckbox"></span>
                                        {{option.name}}
                                </label>
                            </div>
                        </div>
                        <div class="formControl">
                            <label 
                                for="categories"
                                class="formLabel">
                                Filter by Categories
                            </label>
                            <div class="pillRow">
                                <button class="pillBtn">                    
                                    <img src="../../../../assets/images/vehicles-grey.svg" alt="category-icon">
                                    <p>Vehicles</p>
                                </button>
                                <button class="pillBtn">                                                 
                                    <img src="../../../../assets/images/tires-grey.svg" alt="category-icon">
                                    <p>Tires</p>
                                </button>
                                <button class="pillBtn">                    
                                    <img src="../../../../assets/images/dealer-grey.svg" alt="category-icon">
                                    <p>Dealers</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="formControl">
                        <label 
                            for="categories"
                            class="formLabel">
                            Filter by Categories
                        </label>
                        <div class="pillRow">
                            <button class="pillBtn">                    
                                <p>Trucks</p>
                            </button>
                            <button class="pillBtn">                                                 
                                <p>Load carrying</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Buses</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Vans</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Cars</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Material handling</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Port</p>
                            </button>
                            <button class="pillBtn">                    
                                <p>Lifting</p>
                            </button>
                            <button class="linkBtn dmintLink">                    
                                + 32 others
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flexSP">
                    <h2>{{filteredItems.length}} Products</h2>
                    <div class="formControl sort">
                        <button class="toggleCont" (click)="showSorting()">
                            <p>
                                <span>
                                    <img src="../../../../assets/images/sort.svg" alt="">
                                </span>
                                Sort</p>
                            <span *ngIf="selectedItems.length" class="optionSelected" title="{{selectedItems.join(', ')}}">{{ selectedItems.join(', ')}}</span>
                            <img src="../../../../assets/images/chevron-south.svg" alt="chevron-south">
                        </button>
                        <div class="optionsCont" *ngIf="isSortingOpen">
                            <button class="option" (click)="sortByFav()">Favourites</button>
                            <button class="option" (click)="sortByNameAZ()">Name A - Z</button>
                            <button class="option" (click)="sortByNameAZ()">Name Z - A</button>
                            <button class="option" (click)="sortByRecentlyAdded()">Recently Added</button>
                            <button class="option" (click)="sortByRecentlyUpdated()">Recently Updated</button>
                        </div>
                    </div>
                </div>
                <div class="products">
                    <div class="noResults" *ngIf="filteredItems.length === 0">
                        <img src="../../../../assets/images/noResults.png" alt="no-results">
                        <p>No results found.</p>
                    </div>
                    <div class="productCard" 
                        *ngFor="let analyticsProduct of filteredItems"
                        >
                        <div class="banner" [ngClass]="{'dataSet': analyticsProduct.isDashboard!}">

                            <!-- Dashbaord Icon -->
                            <img src="../../../../assets/images/pbiDashboard.svg" alt="power-bi-dashboards-icon" *ngIf="analyticsProduct.isDashboard; else datasetIcon">

                            <!-- Data Set Icon -->
                            <ng-template #datasetIcon>
                                <img src="../../../../assets/images/pbi-datasets.svg" alt="power-bi-dashboards-icon">
                            </ng-template>
                            <div class="typeTag">{{ analyticsProduct.isDashboard ? 'Dashboard' : 'Dataset' }}</div>
                        </div>
                        <h2>{{analyticsProduct.name}}</h2>
                        <p class="desc">
                            {{analyticsProduct.desc}} 
                        </p>
                        <a href="#" class="dmintLink">View More</a>
                        <div class="ctaCont">
                            {{analyticsProduct.isSubscribed}}
                            <button class="primary" *ngIf="analyticsProduct.isSubscribed">
                                Visit site
                                <img src="../../../../assets/images/visitLinkIcon.svg" alt="">
                            </button>
                            <button class="secondary" *ngIf="!analyticsProduct.isSubscribed">
                                SUBSCRIBE
                            </button>
                            <!-- <ng-template #subsBtn>
                                
                            </ng-template> -->
                        </div>
                        <div class="addToFav">
                            <img src="../../../../assets/images/favourite.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            &copy; DMINT Copyright 2023. All Rights Reserved.
        </footer>
    </div>
</div>